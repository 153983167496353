import React from 'react';

const LineDancing = () => {
  return (
    <div>
      <h1>Line Dancing</h1>
      <p>This is the Line Dancing page.</p>
    </div>
  );
};

export default LineDancing;
